import React, { useEffect, useRef, useState, createContext } from "react";
import Compose from "../Compose";
import Message from "../Message";

import "./MessageList.css";
import Toolbar from "../Toolbar";
import {sessionId} from "../../App";
import GPTService,{DatabaseService}from "../../services/gpt";
import MessageBuilderService from "../../services/message-builder";
import TypingIndicator from "../TypingIndicator";
import { useTheme,Theme } from "../../context/ThemeContext";
import { ChatMessageCtx } from "../../services/gpt";
import {ChatContext} from "../../context/ChatContext";
import { saveAs } from 'file-saver';

import AWS from 'aws-sdk';

interface AIData {
  prompt: string;
  completion: string;
}

export interface Message {
  author: string;
  message: string;
  timestamp: number;
}

export default function MessageList() {
  const chatContext = React.useContext(ChatContext);
  const MY_USER_ID = chatContext.chatInfo.MY_USER_ID; 

  //Message controls for sending - waiting and recieving messages
  const [loading, setLoading] = useState(false);
  const [initialMessageReplaced, setInitialMessageReplaced] = useState(false);
  const [messages, setMessages] = useState([
    {
      //TODO Change default entry text
      author: chatContext.chatInfo.AI_NAME,
      message: chatContext.chatInfo.introAiMessage,
      timestamp: new Date().getTime(),
    },
  ] as Message[]);

  // Stays scrolled to bottom.
  const messagesEndRef = useRef(null);

  useEffect(() =>{

    if (chatContext.chatInfo.introAiMessage && !initialMessageReplaced) {
      setInitialMessageReplaced(true);
      setMessages([
        {
          author: chatContext.chatInfo.AI_NAME,
          message: chatContext.chatInfo.introAiMessage,
          timestamp: new Date().getTime(),
        },
      ]);
    }
    /* Adding an event listener to the window object. The event listener is called before the page is
    unloaded. */
    window.addEventListener('beforeunload', handleLeavePage);
    // window.addEventListener('unload', handleSaveConversation)
    return () => {
      /* Removing the event listener. */
      window.removeEventListener('beforeunload', handleLeavePage);
      // window.removeEventListener('unload', handleSaveConversation)
    }
  }, [chatContext.chatInfo.introAiMessage, initialMessageReplaced]);
  
  async function handleLeavePage(e:any)
  {
    // TODO: Create a return from the confirmation message to save the messages to a file
    if(messages.length > 2)
    {
      // saveMessagesToFile();
      // saveMessagesDynamoDB();
    }
    const confirmationMessage = 'Do you want to save the conversation and reload?';
    e.returnValue = confirmationMessage;     // Gecko, Trident, Chrome 34+
    return confirmationMessage;              // Gecko, WebKit, Chrome <34
  }
  /* Scrolling to the bottom of the page. */
  useEffect(() => {
    if (messagesEndRef) {
      (messagesEndRef.current as any).scrollIntoView({ behavior: "smooth" })
    }
  });

  const addMyMessage = async (value: string) => {
    const newMessage = {
      author: MY_USER_ID,
      message: value.trim(),
      timestamp: new Date().getTime(),
    };
    
    setMessages((previous) => [...previous, newMessage]);
    setLoading(true);
    const withMine = [...messages, newMessage];

    let chatMessageCtx:ChatMessageCtx = {
      chatContext: chatContext,
      messages: withMine
    };
    
    const response = await GPTService.getAIResponse(chatMessageCtx,sessionId);
    const responseMessage = {
      author: chatContext.chatInfo.AI_NAME,
      message: response,
      timestamp: new Date().getTime(),
    };
    setMessages((previous) => [...previous, responseMessage]);
    setLoading(false);
  };

  function handleSave(message: Message, editedText: string) {
    // Save edited message to JSON file
    const index = messages.findIndex((m) => m === message);
    console.log(index);
    const updatedMessage = { ...message, message: editedText };
    console.log(updatedMessage);
    const updatedMessages = [...messages];
    console.log(updatedMessages);
    updatedMessages.splice(index, 1, updatedMessage);
    console.log(updatedMessages);
    const editedResponse = {
      prompt: updatedMessages[index-1].message,
      completion: editedText
    };
    console.log(editedResponse);
    setMessages(updatedMessages);
  }
  
// Define function for saving messages to DynamoDB
async function saveMessagesToDatabase() {
  const response = await DatabaseService.saveConversation(sessionId);
  }
  // Define function for submitting feedback
  async function submitFeedback(feedback: boolean) {
    let chatMessageCtx:ChatMessageCtx = {
      chatContext: chatContext,
      messages: messages
    };
    const response = await DatabaseService.submitFeedback(chatMessageCtx,feedback);
    }

  return (
    //TODO insert theme context styling here
    
    <div className="message-list">
      <button className="save-button" onClick={() => saveMessagesToDatabase()}>Save to Database</button>
      <button className="positive-feedback-button" onClick={() => submitFeedback(true)}>Feedback Positive</button>
      <button className="negative-feedback-button" onClick={() => submitFeedback(false)}>Feedback Negative</button>
      <Toolbar/>

      <div className="message-list-container">{MessageBuilderService.getMessages(messages, MY_USER_ID,handleSave)}
      {loading && <TypingIndicator />}
      <div ref={messagesEndRef} />
      </div>
      <Compose addMessage={addMyMessage} />
    </div>
  );
}
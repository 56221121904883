import React, { useState } from 'react';
import moment from 'moment';
import './Message.css';

export default function Message(props: any) {
    const {
      data,
      isMine,
      startsSequence,
      endsSequence,
      showTimestamp,
      isFirstMessage,
      onClickSave,
    } = props;

    const [isEditing, setIsEditing] = useState(false);
    const [editedMessage, setEditedMessage] = useState(data.message);

    const friendlyTimestamp = moment(data.timestamp).format('LLLL');

    const handleEdit = () => {
      setIsEditing(true);
    };

    const handleCancelEdit = () => {
      setIsEditing(false);
      setEditedMessage(data.message);
    };

    const handleSaveEdit = () => {
      setIsEditing(false);
      onClickSave(data,editedMessage);
      // You can use a library like Axios to make a POST request to your server and save the editedResponse to a JSON file
      console.log(editedMessage);
    };

    const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      setEditedMessage(event.target.value);
    };
    
    return (
      <div className={[
        'message',
        `${isMine ? 'mine' : ''}`,
        `${startsSequence ? 'start' : ''}`,
        `${endsSequence ? 'end' : ''}`
      ].join(' ')}>
        {
          showTimestamp &&
            <div className="timestamp">
              { friendlyTimestamp }
            </div>
        }

        <div className="bubble-container">
          { isEditing ?
            <textarea
              className="bubble-edit"
              value={editedMessage}
              onChange={handleInputChange}
            />
            :
            <div className="bubble" title={friendlyTimestamp}>
              { data.message }
            </div>
          }
          { !isMine && !isFirstMessage &&
            <div className="bubble-edit-buttons">
              { isEditing ?
                <>
                  <button className="cancel-button" onClick={handleCancelEdit}>
                    Cancel
                  </button>
                  <button className="save-button" onClick={handleSaveEdit}>
                    Save
                  </button>
                </>
                :
                <button className="edit-button" onClick={handleEdit}>
                  Edit
                </button>
              }
            </div>
          }
        </div>
      </div>
    );
}

import React, {useContext}from "react";
import "./Toolbar.css";
import { ChatContext } from "../../context/ChatContext";
export default function Toolbar() {
  const {chatInfo} = useContext(ChatContext);
  return (
    <div className="toolbar">
      <h1 className="toolbar-title">{chatInfo.chatroomTitle}</h1>
    </div>
  );
}

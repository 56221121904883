/*  */
import React,{createContext} from "react";

import {ChatContextType, Props } from "../@types/chatInfo";

export const defaultChatContext: ChatContextType = {
  chatInfo: {
    MY_USER_ID: '',
    AI_NAME: '',
    startPrompt: '',
    introAiMessage: '',
    chatroomTitle: '',
  },
  setChatInfo: () => {},
};

export const ChatContext = createContext<ChatContextType>(defaultChatContext);

export const ChatContextProvider = ({ value, children }: Props) => {
  return <ChatContext.Provider value={value}>{children}</ChatContext.Provider>;
};

export default ChatContextProvider;


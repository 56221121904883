import React, { useState, useEffect }from 'react';
import logo from './logo.svg';
import './App.css';
import Messenger from './components/Messenger';

import {ChatContextProvider} from './context/ChatContext';
import {iChat} from './@types/chatInfo';
import { InitializeResponse, InitService } from './services/gpt';
import { v4 as uuidv4 } from 'uuid';

export const sessionId = uuidv4()

interface AppState {
  chatInfo: iChat;
}

const App = () => {
  const [chatInfo, setChatInfo] = useState<iChat>({
    MY_USER_ID: '',
    AI_NAME: '',
    startPrompt: '',
    introAiMessage: '',
    chatroomTitle: '',
  });

  const handleChatInfo = (newChatInfo: iChat) => {
    setChatInfo(newChatInfo);
  };

  useEffect(() => {
    const getAppInit = async () => {
      const initResponse: InitializeResponse = await InitService.getAppInit(sessionId);
      if (initResponse.ok) {
        console.log(initResponse);
        const { start_prompt, intro_ai_message, start_sequence, restart_sequence, chatroom_title} = initResponse;
        setChatInfo((prevState : iChat) => ({
          ...prevState,
          startPrompt: start_prompt || '',
          introAiMessage: intro_ai_message || '',
          chatroomTitle: chatroom_title || '',
          MY_USER_ID: restart_sequence || '',
          AI_NAME: start_sequence || '',
        }));
      } else {
        console.error(initResponse.error);
      }
    };

    getAppInit();
  }, []);
    

  return (
    <div className="App">
      <ChatContextProvider value={{ chatInfo, setChatInfo: handleChatInfo }}>
        {/* TODO Refactor and insert functionality for animated rollout menu for dashboard*/}
        {/* <Dashboard /> */}
        <Messenger />
      </ChatContextProvider>
    </div>
  );
};


export default App;
